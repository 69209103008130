export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/'  },
    { icon: 'mdi-account-group', key: 'menu.blank', text: 'Commodity Stakeholder', link: '/stakeholder' },
    { icon: 'mdi-file-account', key: 'menu.blank', text: 'Data Clerk', link: '/dataClerk' },
    //{ icon: 'mdi-group', key: 'menu.blank', text: 'Irrigation Cluster', link: '/cluster' },
    { icon: 'mdi-account-convert', key: 'menu.blank', text: 'DC Activation Request', link: '/dataEntryRequest' },
    //{ icon: 'mdi-chart-bell-curve', key: 'menu.blank', text: 'Summaries', link: '/summary' },
    //{ icon: 'mdi-bell', key: 'menu.blank', text: 'Picture', link: '/picture' } ,
    { icon: 'mdi-logout', key: 'menu.blank', text: 'Logout', link: '/' } 
  ]
}]
